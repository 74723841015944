<div class="contentBox1 grey">
    
</div>
<img id="telAviv" src="/assets/tel-aviv1.jpeg" />

<div class="contentBox2 darkGreen">
    <img id="miners" src="/assets/miners.png" />
    <img id="mapPin1" class="mapPin" src="/assets/map-pin.png" />
<img id="mapPin2" class="mapPin" src="/assets/map-pin.png" />
<img id="mapPin3" class="mapPin" src="/assets/map-pin.png" />
<img id="mapPin4" class="mapPin" src="/assets/map-pin.png" />
<img id="mapPin5" class="mapPin" src="/assets/map-pin.png" />
<img id="mapPin6" class="mapPin" src="/assets/map-pin.png" />

</div>
<div class="contentBox1 grey">

</div>
<div class="contentBox1">

</div>
<div class="contentBox1 white">

</div>
<div class="contentBox1 green">

</div>